<template>
  <div class="mt-1">
    <book-entry-form v-if="mode === 'I'"></book-entry-form>
    <salesmen-accounting v-if="mode === 'II'"></salesmen-accounting>
    <advance-payment v-if="mode === 'III'"></advance-payment>
    <various-accountings v-if="mode === 'V'"></various-accountings>
  </div>
</template>

<script>
import BookEntryForm from "@/components/accounting/book-entries/form/I";
import SalesmenAccounting from "@/components/accounting/book-entries/edit-modes/II";
import VariousAccountings from "@/components/accounting/book-entries/edit-modes/V";
import AdvancePayment from "@/components/accounting/book-entries/edit-modes/III";

export default {
  data() {
    return {
      mode: this.$route.params.mode ? this.$route.params.mode : "I",
      type: this.$route.params.type,
      returnUrl: this.$route.params.returnUrl
        ? this.$route.params.returnUrl
        : null,
      returnData: this.$route.params.returnData
        ? this.$route.params.returnData
        : {},
    };
  },
  components: {
    BookEntryForm,
    SalesmenAccounting,
    AdvancePayment,
    VariousAccountings,
  },
};
</script>
