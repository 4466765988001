<template>
  <div class="mt-1 mx-3">
    <book-entry></book-entry>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BookEntry from "@/components/accounting/book-entries/index.vue";

export default {
  props: {},
  components: {
    BookEntry,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  created() {
    this.notifyQueue({
      text: "Contabilità",
      path: "",
      level: 1,
    });
    this.notifyQueue({
      text: "Fogli cassa",
      path: "module.CONT.FCAS",
      level: 2,
    });
  },
};
</script>
