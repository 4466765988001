<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dettagli Generali" header-tag="header">
                <b-row>
                  <div class="form-group col-md-3">
                    <base-datepicker
                      vid="book_date"
                      :name="beForm[rep].book_date.label"
                      :label="beForm[rep].book_date.label"
                      v-model="form[rep].book_date"
                      :rules="getRules('book_date')"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-datepicker
                      vid="sheet_date"
                      :name="beForm[rep].sheet_date.label"
                      :label="beForm[rep].sheet_date.label"
                      v-model="form[rep].sheet_date"
                      :rules="getRules('sheet_date')"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-input
                      :name="beForm[rep].title.label"
                      vid="title"
                      :label="beForm[rep].title.label"
                      v-model="form[rep].title"
                      :rules="getRules('title')"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-input
                      name="causale"
                      vid="causale"
                      label="Causale"
                      v-model="variousAccountingTitle"
                      readonly
                    />
                  </div>
                </b-row>
              </b-card>
              <b-card
                header="Dati Generali"
                header-tag="header"
                class="mt-1"
                v-if="enableInsurer || enableSalesman || enableRegistry"
              >
                <b-card-text>
                  <b-row>
                    <!-- <div class="col-md-3" v-if="enableInsurer">
                      <base-select
                        :name="beForm[rep].insurer_id.label"
                        vid="insurer_id"
                        :label="beForm[rep].insurer_id.label"
                        :options="companies"
                        v-model="form[rep].insurer_id"
                        :rules="getRules('insurer_id')"
                      />
                    </div> -->
                    <!-- HOTFIX -->
                    <div class="col-md-3" v-if="enableInsurer">
                      <base-select
                        vid="insurer_id"
                        name="Compagnia"
                        label="Compagnia"
                        :options="companies"
                        v-model="form[rep].insurer_id"
                      />
                    </div>
                    <!-- <div class="form-group col-md-3" v-if="enableSalesman">
                      <base-select
                        :name="beForm[rep].brokers[0].label"
                        vid="broker_id"
                        :label="beForm[rep].brokers[0].label"
                        v-model="form.broker_id"
                        :options="broker_options"
                        :rules="getRules('brokers')"
                      />
                    </div> -->
                    <!-- HOTFIX -->
                    <div class="form-group col-md-3" v-if="enableSalesman">
                      <base-select
                        name="Produttore"
                        vid="broker_id"
                        label="Produttore"
                        v-model="form.broker_id"
                        :options="broker_options"
                      />
                    </div>
                  </b-row>
                  <b-row v-if="enableRegistry">
                    <div class="form-group col-md-3">
                      <div>
                        <b-button-toolbar>
                          <b-button-group>
                            <base-input
                              name="Anagrafica"
                              label="Anagrafica"
                              v-model="form.inpt_label"
                              :readonly="true"
                              :rules="getRules('registry_id')"
                            />
                            <b-button
                              class="mt-4 btn-quick"
                              size="sm"
                              text="Button"
                              variant="lisaweb"
                              title="Ricerca Veloce"
                              @click="openQuickSearchRegistry"
                              ><b-icon icon="search"></b-icon
                            ></b-button>
                            <b-button
                              class="mt-4 btn-quick ml-1"
                              size="sm"
                              text="Button"
                              variant="lisaweb"
                              @click="resetRegistryId"
                              title="Elimina Anagrafica"
                              ><b-icon icon="trash"></b-icon
                            ></b-button>
                          </b-button-group>
                        </b-button-toolbar>
                      </div>
                    </div>
                    <div class="registry_data col-md-3">
                      <span
                        v-if="registry_data"
                        class="info"
                        v-html="toInfoData(registry_data, 'registry')"
                      >
                      </span>
                    </div>
                    <quick-search-registry-modal
                      ref="quickSearchRegistry"
                      @input="handleRegistryModalInput"
                    >
                    </quick-search-registry-modal>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card
                header="Casse Astratte"
                header-tag="header"
                class="mt-1"
                v-if="
                  enableEntryDetails &&
                  getFilteredTreasuries(entry_details, 'Y', 'Y', 'N').length > 0
                "
              >
                <b-card-text>
                  <b-row>
                    <div
                      class="form-group col-md-3"
                      v-for="(dtl, index) in getFilteredTreasuries(
                        entry_details,
                        'Y',
                        'Y',
                        'N'
                      )"
                      :key="index"
                    >
                      <base-currency
                        :name="dtl.text"
                        :vid="`detail-${dtl.text}`"
                        :label="`${dtl.text}`"
                        v-model="dtl.gross"
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                        :rules="rules(dtl.id)"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card
                header="Casse Di Sistema"
                header-tag="header"
                class="mt-1"
                v-if="
                  enableEntryDetails &&
                  getFilteredTreasuries(entry_details, 'Y', 'N', 'N').length > 0
                "
              >
                <b-card-text>
                  <b-row>
                    <div
                      class="form-group col-md-3"
                      v-for="(dtl, index) in getFilteredTreasuries(
                        entry_details,
                        'Y',
                        'N',
                        'N'
                      )"
                      :key="index"
                    >
                      <base-currency
                        :name="dtl.text"
                        :vid="`detail-${dtl.text}`"
                        :label="`${dtl.text}`"
                        v-model="dtl.gross"
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                        :rules="rules(dtl.id)"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card
                header="Casse Non di Sistema"
                header-tag="header"
                class="mt-1"
                v-if="
                  enableEntryDetails &&
                  getFilteredTreasuries(entry_details, 'N', 'N', 'N').length > 0
                "
              >
                <b-card-text>
                  <b-row>
                    <div
                      class="form-group col-md-3"
                      v-for="(dtl, index) in getFilteredTreasuries(
                        entry_details,
                        'N',
                        'N',
                        'N'
                      )"
                      :key="index"
                    >
                      <base-currency
                        :name="dtl.text"
                        :vid="`detail-${dtl.text}`"
                        :label="`${dtl?.treasury?.sector?.text ?? dtl.text}`"
                        v-model="dtl.gross"
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                        :rules="rules(dtl.id)"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button
                  @click="goBack()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>

      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import ShowMixin from "@/mixins/ShowMixin";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseCurrency from "@/components/form/BaseCurrency";
import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { toInfoData /* , toRelationString */ } from "@/utils/transforms";
import { getFilteredTreasuries } from "@/utils/accounting";
import { mapGetters } from "vuex";

export default {
  mixins: [FormMixin, ShortcutMixin, ShowMixin],
  data() {
    return {
      repository: "book_entry",
      id: this.$route.params.id,
      returnUrl: this.$route.params.returnUrl
        ? this.$route.params.returnUrl
        : null,
      returnData: this.$route.params.returnData
        ? this.$route.params.returnData
        : {},
      enableInsurer: false,
      enableSalesman: false,
      enableRegistry: false,
      enableEntryDetails: false,
      variousAccountingRules: [],
      entry_details: [],
      variousAccountingId: null,
      variousAccountingTitle: null,
      //entry_details and treasuries
      treasury_options: [],
      treasuries: [],
      entryDetails: [],
      form: {
        inpt_label: null,
        various_accounting_id: null,
        registry_id: null,
        broker_id: null,
        book_entry: {
          insurance_policy_number: null,
          book_date: null,
          sheet_date: null,
          effective_date: null,
          registry: {},
          mandate_code_id: null,
          is_taxable: "N",
          title: null,
          broker: {},
          insurer_id: null,
          net: 0.0,
          tax: 0.0,
          gross: 0.0,
          agency_prov_take: 0.0,
          agency_prov_purchase: 0.0,
          saler_prov_take: 0.0,
          saler_prov_purchase: 0.0,
          various_accounting: {},
        },
        entry_detail: {
          book_entry_id: null,
          treasury_id: null,
          gross: null,
          detail_date: null,
        },
      },
    };
  },
  components: {
    BaseIcon,
    BaseInput,
    BaseSelect,
    BaseDatepicker,
    BaseCurrency,
    QuickSearchRegistryModal,
  },
  methods: {
    toInfoData,
    getFilteredTreasuries,
    goBack() {
      if (this.returnUrl) {
        switch (this.returnUrl) {
          case "accounting-book":
            // this.shortcut(
            //   "module.CONT.FQUA",
            //   null,
            //   null,
            //   "filterAccountingBook"
            // );
            this.$router.push({
              name: "module.CONT.FQUA",
              params: {
                onLandData: {
                  date_from:
                    "date_from" in this.returnData
                      ? this.returnData.date_from
                      : null,
                },
              },
            });
            break;
          default:
            console.log(
              "No returnUrl set matched! Fallback to default back behavior."
            );
            this.$router.back();
            break;
        }
      } else {
        this.$router.back();
      }
    },
    initDefaultFormValues() {
      this.form.book_entry.is_taxable = "N";
    },
    onSubmit() {
      this.form.registry_id
        ? (this.form[this.rep].registry = { [this.form.registry_id]: {} })
        : null;
      this.form.broker_id
        ? (this.form[this.rep].broker = { [this.form.broker_id]: {} })
        : null;
      this.form[this.rep].various_accounting = {
        [this.variousAccountingId]: {},
      };
      let gross = 0.0;
      for (const key in this.entry_details) {
        gross += this.entry_details[key].gross;
      }
      this.form[this.rep].gross = gross;
      // CHECK really need to do that?
      this.$delete(this.form[this.rep], "treasuries");
      this.isLoading = true;
      this.update(this.repository, this.id)
        .then((response) => {
          let data = response.data.data;
          const entryDetailsPromises = this.updateEntryDetails(data);
          this.isLoading = true;
          Promise.all(entryDetailsPromises)
            .then(() => {
              this.onSuccess();
            })
            .catch((error) => {
              this.onFailure(error);
            })
            .finally(() => {
              this.isLoading = false;
            });
        })
        .catch((error) => {
          this.onFailure(error);
          this.fetch();
        });
      // .finally(() => {
      //   this.isLoading = false;
      // });
    },
    updateEntryDetails(book_entry_data) {
      let updateEntryDetails = [];
      this.form[this.rep_entrdtl].book_entry_id = book_entry_data.id;
      this.form[this.rep_entrdtl].detail_date = book_entry_data.book_date;
      Object.values(this.entry_details).forEach((dtl) => {
        this.form[this.rep_entrdtl].gross = dtl.gross;
        this.form[this.rep_entrdtl].treasury_id = dtl.id;
        let trs = this.update(this.rep_entrdtl, dtl.entry_detail_id);
        updateEntryDetails.push(trs);
      });
      return updateEntryDetails;
    },
    onSuccess() {
      this.$showSnackbar({
        preset: "success",
        text: `Azione Completata: Movimento Contabile Aggiornato (V)`,
      });
      if (this.returnUrl) {
        switch (this.returnUrl) {
          case "accounting-book":
            // this.shortcut(
            //   "module.CONT.FQUA",
            //   null,
            //   null,
            //   "filterAccountingBook"
            // );
            this.$router.push({
              name: "module.CONT.FQUA",
              params: {
                onLandData: {
                  date_from:
                    "date_from" in this.returnData
                      ? this.returnData.date_from
                      : null,
                },
              },
            });
            break;
          default:
            console.log("No returnUrl set matched!");
            break;
        }
      } else {
        this.shortcut(
          "module.CONT.MOVV",
          null,
          null,
          "filterVariousAccounting"
        );
      }
    },
    onFailure(error) {
      let errMsg = this.$getErrorMessage(error);
      this.$showSnackbar({
        preset: "error",
        text: `${errMsg}`,
      });
    },
    fetch() {
      this.isLoading = true;
      this.fetchEditForm(this.repository, this.id).then(() => {
        this.variousAccountingRules = this.beRules[this.repository];
        // serve il record del registry con le info, poi chiamo handleRegistryModalInput
        const RepoR = RepositoryFactory.get("registry");
        // serve l'id di various accounting per settre this.variousAccountingId
        const RepoB = RepositoryFactory.get(this.repository);
        let relationString =
          "relations/byVariousAccounting/byEntryDetail/byBroker/byRegistry/byInsurer";

        RepoB.show(this.id, relationString)
          .then((response) => {
            let data = response.data.data;
            this.form.broker_id = data.brokers.length
              ? data.brokers[0].id
              : null;
            this.form.registry_id = data.registries.length
              ? data.registries[0].id
              : null;
            this.form[this.rep].insurer_id = data.insurer_id; // HOTFIX
            this.variousAccountingId = data.various_accountings[0].id;
            this.variousAccountingTitle = `${data.various_accountings[0].code} - ${data.various_accountings[0].title}`;
            this.initEntryDetails(data);
            if (this.form.registry_id) {
              RepoR.show(this.form.registry_id)
                .then((response) => {
                  this.enableGeneralDataSections();
                  this.handleRegistryModalInput(response.data.data);
                })
                .catch((error) => {
                  let errMsg = this.$getErrorMessage(error);
                  this.$showSnackbar({ preset: "error", text: errMsg });
                })
                .finally(() => {
                  this.isLoading = false;
                });
            } else {
              this.enableGeneralDataSections();
            }
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },
    // onSelectVariousAccounting() {
    //   const Repo = RepositoryFactory.get("book_entry");
    //   let querystring = `various_accounting/${id}`;
    //   return Repo.create(querystring)
    //     .then((response) => {
    //       const data = response.data;
    //       this.variousAccountingRules = data.rules;
    //       this.disableEntryDetailSection();
    //       if (this.beForm[this.repository].treasuries) {
    //         this.entry_details = this.entryDetailsMapping();
    //         this.enableEntryDetailSection();
    //       }
    //       // this.variousAccountingId = id;
    //       this.enableGeneralDataSections();
    //     })
    //     .catch((error) => {
    //       let errMsg = this.$getErrorMessage(error);
    //       this.$showSnackbar({
    //         preset: "error",
    //         text: `${errMsg}`,
    //       });
    //     })
    //     .finally(() => {
    //       this.isLoadingVariouAccounting = false;
    //     });
    // },
    enableGeneralDataSections() {
      // this.deleteGeneralDataVmodels();
      this.enableGeneralSection();
      // this.beRules["various_accounting"] = {
      //   insurer_id: this.variousAccountingRules["insurer_id"],
      //   registry_id: this.variousAccountingRules["registry"],
      //   broker_id: this.variousAccountingRules["broker"],
      // };
    },
    enableEntryDetailSection() {
      this.enableEntryDetails = true;
    },
    disableEntryDetailSection() {
      this.entry_details = [];
      this.enableEntryDetails = false;
    },
    enableGeneralSection() {
      // this.enableInsurer =
      //   this.beForm[this.repository].insurer_id &&
      //   this.beForm[this.repository].insurer_id.value
      //     ? true
      //     : false;
      // HOTFIX
      this.enableInsurer = this.form[this.rep].insurer_id ? true : false;
      this.enableSalesman = this.form.broker_id ? true : false;
      this.enableRegistry = this.form.registry_id ? true : false;
    },
    disableGeneralSection() {
      this.enableInsurer = false;
      this.enableSalesman = false;
      this.enableRegistry = false;
    },
    deleteGeneralDataVmodels() {
      this.form.broker_id = null;
      this.form.registry_id = null;
      this.form[this.rep].insurer_id = null;
    },
    entryDetailsMapping(data) {
      let sectors = this.getSectors();
      return this.beForm[this.repository].treasuries.map((treasury) => {
        let gross = 0;
        let entry_detail_id = null;
        let found = data.entry_details.find(
          (e) => e.treasury.id === treasury.id
        );
        if (found) {
          gross = found.gross;
          entry_detail_id = found.id;
        }
        const sector = sectors.find(
          (sector) => sector.value === treasury.sector_id
        );
        return {
          option: treasury.value,
          id: treasury.id,
          text: `${treasury.title} (${treasury.code})`,
          gross: gross,
          entry_detail_id: entry_detail_id,
          treasury: {
            sector: {
              id: treasury.sector_id,
              code: sector.code,
              text: sector.text,
              is_abstract: {
                value: sector.is_abstract,
              },
              is_system: {
                value: sector.is_system,
              },
              is_agency: {
                value: sector.is_agency,
              },
            },
          },
        };
      });
    },
    rules(treasury_id) {
      const rule = this.variousAccountingRules.treasuries.find(
        (tr) => tr.id === treasury_id
      );
      this.beRules["treasury"] = {
        gross: rule.gross,
      };
      return this.getRules("gross", "treasury");
    },
    initEntryDetails(data) {
      this.disableEntryDetailSection();
      if (this.beForm[this.repository].treasuries) {
        this.entry_details = this.entryDetailsMapping(data);
        this.enableEntryDetailSection();
      }
      // la edit non lo resituisce!!!
      // this.variousAccountingId = this.beForm[this.repository].various_accounting_id;
    },
    openQuickSearchRegistry() {
      this.form.registry_id = null;
      this.$bvModal.show("quickSearchRegistryModal");
    },
    handleRegistryModalInput(value) {
      this.form.registry_id = value.id;
      this.registry_data = value;
      this.form.inpt_label = this.toInfoData(
        this.registry_data,
        "registryfullname"
      );
      this.$bvModal.hide("quickSearchRegistryModal");
    },
    resetRegistryId() {
      this.registry_data = null;
      this.form.inpt_label = null;
      this.form.registry_id = null;
    },
    ...mapGetters("auth", {
      getInsurers: "insurers",
      getSalesmen: "salesmen",
      getSectors: "sectors",
    }),
  },
  mounted() {
    this.companies = this.getInsurers();
    this.broker_options = this.getSalesmen();
    this.initDefaultFormValues();
  },
  beforeMount() {
    this.fetch();
  },
  computed: {
    rep() {
      return this.repository;
    },
    rep_entrdtl() {
      return "entry_detail";
    },
  },
};
</script>
