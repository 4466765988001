<template>
  <b-overlay center :show="isLoading" rounded="sm" class="mt-2">
    <div v-if="!isLoading">
      <div v-if="ok">
        <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
          <b-form @submit.prevent="">
            <b-card header="Dettagli Generali" header-tag="header" class="mt-2">
              <b-card-text>
                <b-row>
                  <div class="form-group col-md-3">
                    <base-datepicker
                      vid="book_date"
                      :name="beForm[rep].book_date.label"
                      label="Data effetto"
                      v-model="form[rep].book_date"
                      :rules="getRules('book_date')"
                      :readonly="true"
                      :disabled="true"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-datepicker
                      vid="sheet_date"
                      :name="beForm[rep].sheet_date.label"
                      :label="beForm[rep].sheet_date.label"
                      v-model="form[rep].sheet_date"
                      :rules="getRules('sheet_date')"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-select
                      :name="beForm[rep].broker.label"
                      vid="broker"
                      :label="beForm[rep].broker.label"
                      v-model="form.broker_id"
                      :options="broker_options"
                      :rules="{ required: true }"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-input
                      name="insurance_number"
                      vid="insurance_number"
                      label="Numero polizza"
                      v-model="form[rep].insurance_policy_number"
                      :readonly="true"
                    />
                  </div>
                </b-row>
                <b-row>
                  <div class="col-md-3">
                    <base-select
                      name="Ramo"
                      vid="branch"
                      label="Ramo"
                      :options="risk_branches"
                      v-model="risk_branch_id"
                      :multiple="false"
                      disabled
                    />
                  </div>
                  <div class="col-md-3">
                    <base-select
                      name="Prodotto"
                      vid="product"
                      label="Prodotto"
                      :options="prdct"
                      v-model="insurance_risk_id"
                      disabled
                    />
                  </div>
                </b-row>
              </b-card-text>
            </b-card>
            <b-card
              header="Collaborazioni"
              header-tag="header"
              class="mt-1"
              v-if="form.collaborator_id"
            >
              <b-card-text>
                <b-row>
                  <div class="form-group col-md-3">
                    <base-select
                      v-if="form.collaborator_id"
                      vid="collaborator_id"
                      name="Collaborazioni"
                      label="Collaborazioni"
                      v-model="form.collaborator_id"
                      :options="cooperators"
                      :disabled="true"
                    />
                  </div>
                  <div
                    class="form-group col-md-3"
                    v-show="form.collaborator_id"
                  >
                    <base-select
                      vid="tipo_incasso"
                      name=""
                      label="Tipo Incasso"
                      v-model="form.collaboration_type"
                      :options="[
                        { text: 'Incasso diretto', value: 0 },
                        { text: 'Tramite collaborazione', value: 1 },
                        { text: 'Nessuna ', value: 2 },
                      ]"
                    />
                  </div>
                </b-row>
              </b-card-text>
            </b-card>
            <b-card header="Valore Contabile" header-tag="header" class="mt-1">
              <b-card-text>
                <b-row>
                  <div class="form-group col-md-3" v-if="!hasBreakdown">
                    <base-currency
                      :name="beForm[rep].net.label"
                      vid="net"
                      :label="beForm[rep].net.label"
                      v-model="form[rep].net"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                      readonly
                      :rules="getRules('net')"
                    />
                  </div>
                  <div class="form-group col-md-3" v-if="!hasBreakdown">
                    <base-currency
                      :name="beForm[rep].tax.label"
                      vid="tax"
                      label="Tasse"
                      v-model="form[rep].tax"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                      readonly
                      :rules="getRules('tax')"
                    />
                  </div>
                  <div class="form-group col-md-3" v-if="!hasBreakdown">
                    <base-input
                      :name="beForm[rep].gross.label"
                      vid="gross"
                      :label="beForm[rep].gross.label"
                      v-model="insuranceAncillariesGrossPremiumSum"
                      :readonly="true"
                      custom_type="currency"
                      :rules="getRules('gross')"
                    />
                  </div>
                </b-row>

                <b-table
                  hover
                  :items="insuranceAncillariesBreakdowns.breakdowns"
                  :fields="fields"
                  ref="insuranceAncillariesBreakdowns"
                  v-if="hasBreakdown"
                >
                  <template v-slot:cell(net)="row">
                    <div class="form-group col-md-7">
                      <base-currency
                        :name="row.field.label"
                        :vid="`net-${row.item.id}`"
                        :label="row.field.label"
                        v-model="row.item.pivot.net"
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                        :readonly="true"
                      />
                    </div>
                  </template>
                  <template v-slot:cell(tax)="row">
                    <div class="form-group col-md-7">
                      <base-currency
                        :name="row.field.label"
                        :vid="`tax-${row.item.id}`"
                        :label="row.field.label"
                        v-model="row.item.pivot.tax"
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                        :readonly="true"
                      />
                    </div>
                  </template>
                  <template v-slot:cell(grossrow)="row">
                    <div class="form-group col-md-7">
                      <base-input
                        name="grossrow"
                        :vid="`grossrow-${row.item.id}`"
                        label="Lordo Riga"
                        custom_type="currency"
                        :value="
                          grossRowInsuranceAncillaries(
                            row.item.pivot.tax,
                            row.item.pivot.net
                          )
                        "
                        :readonly="true"
                      />
                    </div>
                  </template>
                  <template slot="bottom-row">
                    <td>Totale</td>
                    <td>
                      <div class="form-group col-md-7">
                        <base-label
                          :value="insuranceAncillariesNetSum"
                          class="net-sum-label"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-group col-md-7">
                        <base-label
                          :value="insuranceAncillariesTaxSum"
                          class="tax-sum-label"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-group col-md-7">
                        <base-label
                          class="gross-sum-label"
                          :value="insuranceAncillariesGrossSum"
                        />
                      </div>
                    </td>
                  </template>
                </b-table>
              </b-card-text>
            </b-card>
            <b-card
              v-if="agencyHasFee"
              header="Diritti"
              header-tag="header"
              class="mt-1"
            >
              <b-card-text>
                <b-row>
                  <div class="form-group col-md-3">
                    <base-currency
                      name="fee_net"
                      vid="fee_net"
                      label="Netto"
                      v-model="form[rep].fee_net"
                      readonly
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-currency
                      name="fee_tax"
                      vid="fee_tax"
                      label="Tasse"
                      v-model="form[rep].fee_tax"
                      readonly
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-currency
                      name="fee_gross"
                      vid="fee_gross"
                      label="Lordo"
                      v-model="form[rep].fee_gross"
                      readonly
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                    />
                  </div>
                </b-row>
              </b-card-text>
            </b-card>
            <b-card
              header="PROVVIGIONE AGENZIA"
              header-tag="header"
              class="mt-1"
            >
              <b-card-text>
                <b-row>
                  <div class="form-group col-md-3" v-if="hasBreakdown">
                    <b-form-checkbox
                      v-model="hasAgencyBreakdown"
                      name="check-button"
                      switch
                      >{{
                        !hasAgencyBreakdown
                          ? agency_section_breakdown_text
                          : agency_section_text
                      }}</b-form-checkbox
                    >
                  </div>
                </b-row>
                <b-row>
                  <div
                    class="form-group col-md-3"
                    v-if="!hasBreakdown || !hasAgencyBreakdown"
                  >
                    <base-currency
                      :name="beForm[rep].agency_prov_take.label"
                      vid="agency_prov_take"
                      :label="beForm[rep].agency_prov_take.label"
                      v-model="form[rep].agency_prov_take"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                      :rules="getRules('agency_prov_take')"
                    />
                  </div>
                  <div
                    class="form-group col-md-3"
                    v-if="!hasBreakdown || !hasAgencyBreakdown"
                  >
                    <base-currency
                      :name="beForm[rep].agency_prov_purchase.label"
                      vid="agency_prov_purchase"
                      :label="beForm[rep].agency_prov_purchase.label"
                      v-model="form[rep].agency_prov_purchase"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                      :rules="getRules('agency_prov_purchase')"
                    />
                  </div>
                </b-row>

                <b-table
                  hover
                  :items="insuranceAncillariesAgencyBreakdowns.breakdowns"
                  :fields="agencyFields"
                  ref="insuranceAncillariesAgencyBreakdowns"
                  v-if="hasBreakdown && hasAgencyBreakdown"
                >
                  <template v-slot:cell(take)="row">
                    <div class="form-group col-md-10">
                      <base-currency
                        :name="row.field.label"
                        :vid="`agency_prov_take-${row.item.id}`"
                        :label="row.field.label"
                        v-model="row.item.pivot.agency_prov_take"
                        custom_type="currency"
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                      />
                    </div>
                  </template>
                  <template v-slot:cell(purchase)="row">
                    <div class="form-group col-md-10">
                      <base-currency
                        :name="row.field.label"
                        :vid="`agency_purchase-${row.item.id}`"
                        :label="row.field.label"
                        v-model="row.item.pivot.agency_prov_purchase"
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                      />
                    </div>
                  </template>
                  <template v-slot:cell(gross)="row">
                    <div class="form-group col-md-10">
                      <base-input
                        :name="row.field.label"
                        vid="gross_row"
                        :label="row.field.label"
                        custom_type="currency"
                        :value="grossRowAncillaries(row.item.id)"
                        :rules="getRules('agency_prov_purchase')"
                        :readonly="true"
                      />
                    </div>
                  </template>
                  <template slot="bottom-row">
                    <td>Totale</td>
                    <td>
                      <div class="form-group col-md-10">
                        <base-label />
                      </div>
                    </td>
                    <td>
                      <div class="form-group col-md-10">
                        <base-label
                          class="take-sum-label"
                          :value="agencyTakeSum"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-group col-md-10">
                        <base-label
                          class="purchase-sum-label"
                          :value="agencyPurchaseSum"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-group col-md-10">
                        <base-label
                          class="take-sum-label"
                          :value="agencyProvTotal"
                        />
                      </div>
                    </td>
                  </template>
                </b-table>
              </b-card-text>
            </b-card>
            <b-card
              header="PROVVIGIONE PRODUTTORE"
              header-tag="header"
              class="mt-1"
            >
              <b-card-text>
                <b-row>
                  <div class="form-group col-md-3">
                    <b-form-checkbox
                      v-model="computeBrokerStatementMode"
                      name="check-button"
                      switch
                      :disabled="hasSalerBreakdown"
                    >
                      Calcola in fase rendicontale utilizzando il capitolato
                      provvigionale
                    </b-form-checkbox>
                  </div>
                </b-row>
                <b-row>
                  <div class="form-group col-md-3" v-if="hasBreakdown">
                    <b-form-checkbox
                      v-model="hasSalerBreakdown"
                      name="check-button"
                      switch
                      >{{
                        !hasSalerBreakdown
                          ? saler_section_breakdown_text
                          : agency_section_text
                      }}</b-form-checkbox
                    >
                  </div>
                </b-row>
                <b-row>
                  <div
                    class="form-group col-md-3"
                    v-if="!hasBreakdown || !hasSalerBreakdown"
                  >
                    <base-currency
                      :name="beForm[rep].saler_prov_take.label"
                      vid="saler_prov_take"
                      :label="beForm[rep].saler_prov_take.label"
                      v-model="form[rep].saler_prov_take"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                      :rules="getRules('saler_prov_take')"
                      :disabled="computeBrokerStatementMode"
                    />
                  </div>
                  <div
                    class="form-group col-md-3"
                    v-if="!hasBreakdown || !hasSalerBreakdown"
                  >
                    <base-currency
                      :name="beForm[rep].saler_prov_purchase.label"
                      vid="saler_prov_purchase"
                      :label="beForm[rep].saler_prov_purchase.label"
                      v-model="form[rep].saler_prov_purchase"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                      :rules="getRules('saler_prov_purchase')"
                      :disabled="computeBrokerStatementMode"
                    />
                  </div>
                </b-row>

                <b-table
                  hover
                  :items="insuranceAncillariesSalerBreakdowns.breakdowns"
                  :fields="salerFields"
                  ref="insuranceAncillariesSalerBreakdowns"
                  v-if="hasBreakdown && hasSalerBreakdown"
                >
                  <template v-slot:cell(take)="row">
                    <div class="form-group col-md-10">
                      <base-currency
                        :name="row.field.label"
                        :vid="`saler_prov_take-${row.item.id}`"
                        :label="row.field.label"
                        v-model="row.item.pivot.saler_prov_take"
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                      />
                    </div>
                  </template>
                  <template v-slot:cell(purchase)="row">
                    <div class="form-group col-md-10">
                      <base-currency
                        :name="row.field.label"
                        :vid="`saler_prov_purchase-${row.item.id}`"
                        :label="row.field.label"
                        v-model="row.item.pivot.saler_prov_purchase"
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                      />
                    </div>
                  </template>
                  <template v-slot:cell(gross)="row">
                    <div class="form-group col-md-10">
                      <base-input
                        :name="row.field.label"
                        :vid="`total_row-${row.item.id}`"
                        :label="row.field.label"
                        custom_type="currency"
                        :value="grossRowAncillaries(row.item.id)"
                        :readonly="true"
                      />
                    </div>
                  </template>
                  <template slot="bottom-row">
                    <td>Totale</td>
                    <td>
                      <div class="form-group col-md-10">
                        <base-label />
                      </div>
                    </td>
                    <td>
                      <div class="form-group col-md-10">
                        <base-label
                          class="purchase-sum-label"
                          :value="salerPurchaseSum"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-group col-md-10">
                        <base-label
                          class="take-sum-label"
                          :value="salerTakeSum"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="form-group col-md-10">
                        <base-label
                          class="take-sum-label"
                          :value="salerProvTotal"
                        />
                      </div>
                    </td>
                  </template>
                </b-table>

                <b-row>
                  <div class="form-group col-md-3">
                    <base-currency
                      name="fee_tax"
                      vid="fee_tax"
                      label="Diritti di incasso produttore 
                      "
                      v-model="form[rep].saler_fee_take"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                    />
                  </div>
                </b-row>
              </b-card-text>
            </b-card>

            <b-card
              v-if="!hasBreakdown && hasInsurerParticipant"
              header="Coassicurazioni - Delega Nostra"
              header-tag="header"
              class="mt-1"
            >
              <b-card-text>
                <b-row
                  v-for="(insurer_partipant, index) in insurerParticipants"
                  :key="index"
                >
                  <div class="form-group col-md-3">
                    <span>{{
                      `Compagnia Coass: ${insurer_partipant.title}-${insurer_partipant.code}`
                    }}</span
                    ><br />
                    <span
                      >Netto:
                      {{ toLocaleCurrency(insurer_partipant.pivot.net) }}</span
                    ><br />
                    <span
                      >Tasse:
                      {{ toLocaleCurrency(insurer_partipant.pivot.tax) }}</span
                    ><br />
                    <span
                      >Lordo:
                      {{
                        toLocaleCurrency(insurer_partipant.pivot.gross)
                      }}</span
                    ><br />
                  </div>
                  <div class="form-group col-md-3">
                    <base-currency
                      name="participant_prov_purchse"
                      vid="participant_prov_purchse"
                      label="Provvigione Acquisto"
                      v-model="
                        insurer_partipant.pivot.participant_prov_purchase
                      "
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <base-currency
                      name="participant_prov_take"
                      vid="participant_prov_take"
                      label="Provvigione Incasso"
                      v-model="insurer_partipant.pivot.participant_prov_take"
                      :options="{
                        currency: 'EUR',
                        locale: 'it-IT',
                        precision: 2,
                      }"
                    />
                  </div>
                </b-row>
              </b-card-text>
            </b-card>

            <b-card
              header="Forme di pagamento"
              header-tag="header"
              class="mt-1"
              v-if="form.collaboration_type !== BY_COLLABORATION"
            >
              <b-card-text>
                <div
                  class="switch"
                  v-if="!hasBreakdown && hasInsurerParticipant"
                >
                  <b-form-checkbox
                    v-model="storeInsureParticipantGross"
                    name="check-button"
                    switch
                    :disabled="hasBreakdown"
                  >
                    Incassa anche premio coass
                  </b-form-checkbox>
                </div>
                <div class="mt-4">
                  <b-button
                    v-show="
                      form[rep].gross < 0 &&
                      sumEntryDetails - form[rep].gross !== 0
                    "
                    type="button"
                    variant="lisaweb"
                    size="sm"
                    class="mb-2"
                    @click="onAcconto()"
                    >Chiudi con acconto
                  </b-button>
                  <entry-details
                    ref="edRef"
                    :details="dtls"
                    v-model="form"
                    :rules="getRules('gross')"
                    :repository="repository"
                    :amountField="
                      storeInsureParticipantGross
                        ? 'byRemainderFunction'
                        : 'calculatedGross'
                    "
                    :remainderFunction="
                      storeInsureParticipantGross
                        ? byRemainderFunctionInsurerParticipant
                        : 0
                    "
                    enableRemainder
                    canDelete
                    @select="
                      selectCreateEntryDetail(
                        form.book_entries.entry_details,
                        ...arguments
                      )
                    "
                    @unselect="
                      unselectCreateEntryDetail(
                        form.book_entries.entry_details,
                        ...arguments
                      )
                    "
                    @delete="
                      deleteCreateEntryDetail(
                        form.book_entries.entry_details,
                        ...arguments
                      )
                    "
                  ></entry-details>
                </div>
              </b-card-text>
            </b-card>
            <div class="py-2 mb-4">
              <b-button
                @click="handleSubmit(onSubmit)"
                type="button"
                :disabled="invalid"
                variant="lisaweb"
                size="sm"
              >
                Incassa
              </b-button>
              <b-button
                @click="$router.back()"
                variant="lisaweb"
                size="sm"
                class="float-right"
                ><b-icon-chevron-double-left
                  font-scale="0.9"
                ></b-icon-chevron-double-left
                >Torna indietro</b-button
              >
            </div>
          </b-form>
        </validation-observer>
      </div>
      <div v-else>
        {{ errorMessage }}
      </div>
    </div>
    <template #overlay>
      <div class="text-center mt-5">
        <base-icon name="loading" width="35" height="35" />
        <p id="cancel-label">Operazione in corso...</p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseCurrency from "@/components/form/BaseCurrency";
import BaseInput from "@/components/form/BaseInput";
import BaseLabel from "@/components/form/BaseLabel";
import BaseSelect from "@/components/form/BaseSelect";
import EntryDetails from "@/components/form/EntryDetails";
import EntryDetailsMixin from "@/mixins/EntryDetailsMixin";
import { toInfoData /* , toRelationString */ } from "@/utils/transforms";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { toLocaleCurrency } from "@/utils/strings";
import { mapGetters } from "vuex";
import moment from "moment";
moment.locale("it");

export default {
  mixins: [FormMixin, EntryDetailsMixin, ShortcutMixin, StorageGetterMixin],
  data() {
    return {
      titleId: this.$route.params.titleId,
      insuranceRiskId: this.$route.params.insuranceRiskId,
      item: this.$route.params.item,
      isLoading: false,
      extraTreasuries: ["AB", "SS"],
      onlyRealTreasuries: true,
      skipAgencyTreasuries: true,
      skipTreasuries: this.getExcludedTreasuries()("OTHER"),
      broker_options: [],
      cooperators: [],
      hasInsurerParticipant: false,
      storeInsureParticipantGross: true, // #1172
      insurerParticipants: [],
      hasBreakdown: false,
      hasAgencyBreakdown: false,
      hasSalerBreakdown: false,
      computeBrokerStatementMode: true,
      repository: "book_entry",
      mandatoryFields: {},
      agency_section_text: "Disabilitare scorpori di agenzia",
      agency_section_breakdown_text: "Abilitare scorpori di agenzia",
      saler_section_text: "Disabilitare scorpori del produttore",
      saler_section_breakdown_text: "Abilitare scorpori del produttore",
      //EntryDetails component
      treasury_options: [],
      treasuries: [],
      entryDetails: [],
      //Breakdowns
      insuranceAncillariesAgencyBreakdowns: [],
      insuranceAncillariesSalerBreakdowns: [],
      insuranceAncillariesBreakdowns: [],
      //Multiselect Options
      risk_branches: [],
      risk_branch_id: null,
      insurance_risk_id: null,
      products: [],
      book_tags_opt: [],
      //Table Fields
      salerFields: [
        {
          key: "title",
          label: "Scorporo",
        },
        {
          key: "gross",
          label: "Totale",
        },
        {
          key: "take",
          label: "Incasso",
        },
        {
          key: "purchase",
          label: "Acquisto",
        },
        {
          key: "prov",
          label: "Totale Prov. Agenzia",
        },
      ],
      agencyFields: [
        {
          key: "title",
          label: "Scorporo",
        },
        {
          key: "gross",
          label: "Totale",
        },
        {
          key: "take",
          label: "Incasso",
        },
        {
          key: "purchase",
          label: "Acquisto",
        },
        {
          key: "prov",
          label: "Totale Prov. Produttore",
        },
      ],
      fields: [
        {
          key: "title",
          label: "Scorporo",
        },
        {
          key: "net",
          label: "Netto",
        },
        {
          key: "tax",
          label: "Tasse",
        },
        {
          key: "grossRow",
          label: "Lordo Riga",
        },
      ],
      // Book Entry Form
      form: {
        broker_id: null,
        registry_id: null,
        collaborator_id: null,
        collaboration_type: null,
        book_entry: {
          insurance_policy_number: null,
          book_date: null,
          sheet_date: moment().format("YYYY-MM-DD"),
          effective_date: null,
          registry: {},
          mandate_code_id: null,
          is_taxable: "N",
          title: null,
          broker: {},
          insurer_id: null,
          net: 0.0,
          tax: 0.0,
          gross: 0.0,
          agency_prov_take: 0.0,
          agency_prov_purchase: 0.0,
          saler_prov_take: 0.0,
          saler_prov_purchase: 0.0,
          insurance_ancillary: {},
          fee_gross: 0.0,
          fee_net: 0.0,
          fee_tax: 0.0,
          saler_fee_take: 0.0,
        },
        // Breakdown
        breakdown: {},
        // Entry Detail Form
        entry_detail: {
          book_entry_id: null,
          treasury_id: null,
          gross: null,
          detail_date: null,
        },
        book_entries: {
          entry_details: {},
        },
        // Book Tag
        book_tag: {},
        // Insurance Ancillary
        insurance_ancillary: {}, // LEX
      },
      BY_COLLABORATION: 1, // 'Tramite Collaboratore'
    };
  },
  methods: {
    toLocaleCurrency,
    toInfoData,
    checkEntryDetailsTotal() {
      const totalEntryDetails = this.storeInsureParticipantGross
        ? this.byRemainderFunctionInsurerParticipant()
        : this.form[this.rep].gross;
      return (
        this.form.collaboration_type !== this.BY_COLLABORATION &&
        totalEntryDetails != parseFloat(this.entryDetailsCreateTotal.toFixed(2))
      );
    },
    onSubmit() {
      if (this.checkEntryDetailsTotal()) {
        this.$showSnackbar({
          preset: "error",
          text: `Il lordo deve essere uguale al totale delle forme di pagamento`,
        });
        return;
      }

      if (this.hasEntryDetail("SS") && this.hasEntryDetail("AB")) {
        this.$showSnackbar({
          preset: "error",
          text: `Non è possibile utilizzare insieme le forme di pagamento abbuono e sospeso`,
        });
        return;
      }

      this.setFormData();

      this.isLoading = true;

      if (this.computeBrokerStatementMode) {
        this.form[this.rep].saler_prov_purchase = null;
        this.form[this.rep].saler_prov_take = null;
      }

      this.updateInsuranceAncillary()
        .then(() => {
          this.store(this.repository)
            .then((response) => {
              const data = response.data.data;

              const entryDetailsPromises =
                this.form.collaboration_type !== this.BY_COLLABORATION
                  ? this.storeEntryDetails(data)
                  : [];

              const staticEntryDetailsPromises =
                this.storeStaticEntryDetails(data);

              let breakdownablesPromises = [];
              if (this.hasBreakdown) {
                breakdownablesPromises.push(this.storeBreakdownables());
              }

              let bookTagPromise = [];
              if (Object.keys(this.form.book_tag).length) {
                bookTagPromise.push(
                  this.addBookTag(data.id, "book_tag", {
                    book_tag: this.form.book_tag,
                  })
                );
              }

              let insurerParticipantsPromise = [];
              if (!this.hasBreakdown && this.hasInsurerParticipant) {
                insurerParticipantsPromise.push(
                  this.updateCoassAncillary("insurer_participant")
                );
              }

              Promise.all([
                ...entryDetailsPromises,
                ...breakdownablesPromises,
                ...staticEntryDetailsPromises,
                ...bookTagPromise,
                ...insurerParticipantsPromise,
              ])
                .then(() => {
                  this.onSuccess();
                  this.isLoading = false;
                })
                .catch((error) => {
                  this.onFailure(error);
                  this.isLoading = false;
                });
            })
            .catch((error) => {
              this.onFailure(error);
              this.isLoading = false;
            });
        })
        .catch((error) => {
          this.onFailure(error);
          this.isLoading = false;
        });
    },
    updateCoassAncillary(relation) {
      let payload = {
        insurer_participant: {},
      };
      let insuranceAncillaryId;
      // Coass Detail
      this.insurerParticipants.forEach((e) => {
        insuranceAncillaryId = e.pivot.insurance_ancillary_id;
        payload.insurer_participant[e.id] = {
          share: e.pivot.share || 0,
          net: e.pivot.net || 0,
          tax: e.pivot.tax || 0,
          //gross: (e.pivot.net || 0) + (e.pivot.tax || 0),
          gross: e.pivot.gross || 0,
          participant_prov_purchase: e.pivot.participant_prov_purchase || 0,
          participant_prov_take: e.pivot.participant_prov_take || 0,
        };
      });

      const Repo = RepositoryFactory.get("insurance_ancillary");
      return Repo.pivot_update(insuranceAncillaryId, relation, payload);
    },
    byRemainderFunctionInsurerParticipant() {
      return (
        this.sumPivot(this.insurerParticipants, "gross") +
        this.form[this.rep].gross
      );
    },
    hasEntryDetail(code) {
      const entryDetailsCodes = this.dtls
        .filter((dtl) => dtl.selected)
        .map((dtl) => {
          let code;
          let found = this.$store.state.auth.treasuries.find(
            (e) => e.id === dtl.selected
          );
          if (found) {
            code = found.code;
          }
          return {
            // display: this.$store.state.auth.treasuries.filter(
            //   (treasury) => treasury.id === dtl.selected
            // )[0].code,
            display: code,
          };
        });
      const check = entryDetailsCodes.find(
        (entryDetailCode) => entryDetailCode.display === code
      );
      if (check) {
        return true;
      }
      return false;
    },
    storeStaticEntryDetails(book_entry_data) {
      const staticEntryDetails = [];
      let found;
      let COCid;
      let COPid;
      let ENid;
      let PRid;
      // Set id and book_date
      this.form[this.rep_entrdtl].book_entry_id = book_entry_data.id;
      this.form[this.rep_entrdtl].detail_date = book_entry_data.book_date;

      //"Casse Statiche"
      // let ENid
      found = this.$store.state.auth.treasuries.find((el) => el.code === "EN");
      if (found) {
        ENid = found.id;
      }
      // let PRid
      found = this.$store.state.auth.treasuries.find((el) => el.code === "PR");
      if (found) {
        PRid = found.id;
      }

      if (ENid && PRid) {
        let bodyEN = {
          treasury_id: ENid,
          gross: this.form[this.rep].gross,
        };
        let bodyPR = {
          treasury_id: PRid,
          gross:
            this.form[this.rep].agency_prov_take +
            this.form[this.rep].agency_prov_purchase,
        };
        [bodyEN, bodyPR].forEach((body) => {
          this.form[this.rep_entrdtl].gross = body.gross;
          this.form[this.rep_entrdtl].treasury_id = body.treasury_id;
          let staticTrs = this.store(this.rep_entrdtl);
          staticEntryDetails.push(staticTrs);
        });
      }

      if (staticEntryDetails.length) {
        found = this.$store.state.auth.treasuries.find(
          (el) => el.code === "COC"
        );
        if (found) {
          COCid = found.id;
        }
        found = this.$store.state.auth.treasuries.find(
          (el) => el.code === "COP"
        );
        if (found) {
          COPid = found.id;
        }
        if (COCid && COPid) {
          let bodyCOC = {
            treasury_id: COCid,
            gross: this.sumPivot(this.insurerParticipants, "gross"),
          };
          let bodyCOP = {
            treasury_id: COPid,
            gross:
              this.sumPivot(
                this.insurerParticipants,
                "participant_prov_purchase"
              ) +
              this.sumPivot(this.insurerParticipants, "participant_prov_take"),
          };
          [bodyCOC, bodyCOP].forEach((body) => {
            this.form[this.rep_entrdtl].gross = body.gross;
            this.form[this.rep_entrdtl].treasury_id = body.treasury_id;
            let staticTrs = this.store(this.rep_entrdtl);
            staticEntryDetails.push(staticTrs);
          });
        }
      }
      return staticEntryDetails;
    },
    storeEntryDetails(book_entry_data) {
      let storeEntryDetails = [];
      this.form[this.rep_entrdtl].book_entry_id = book_entry_data.id;
      this.form[this.rep_entrdtl].detail_date = book_entry_data.book_date;

      Object.values(this.form.book_entries.entry_details)
        .filter((treasury) => treasury.type !== null && treasury.type !== null)
        .forEach((filteredTreasury) => {
          this.form[this.rep_entrdtl].gross = filteredTreasury.amount;
          this.form[this.rep_entrdtl].treasury_id = filteredTreasury.type;
          let trs = this.store(this.rep_entrdtl);
          storeEntryDetails.push(trs);
        });
      return storeEntryDetails;
    },
    addBookTag(bookEntryId, relation, payload) {
      const Repo = RepositoryFactory.get("book_entry");
      return Repo.pivot_store(bookEntryId, relation, payload);
    },
    setFormData() {
      this.form[this.rep].book_date = this.form[this.rep].sheet_date;
      this.form[this.rep].registry = this.form.registry_id
        ? { [this.form.registry_id]: {} }
        : null;
      this.form[this.rep].broker = this.form.broker_id
        ? { [this.form.broker_id]: {} }
        : null;
      this.form[this.rep].insurance_ancillary = {
        [this.titleId]: {
          insurance_risk_id: this.insuranceRiskId,
          insurance_policy_number: this.form[this.rep].insurance_policy_number,
        },
      };
      if (this.form.collaborator_id) {
        this.form[this.rep].broker[this.form.collaborator_id] = {};
      }
      if (this.hasBreakdown) {
        this.initializeBookEntryForm();
      }
      if (!this.hasBreakdown && this.storeInsureParticipantGross) {
        this.form[this.rep].gross =
          this.form[this.rep].net +
          this.form[this.rep].tax +
          this.sumPivot(this.insurerParticipants, "gross");
        this.form[this.rep].net += this.sumPivot(
          this.insurerParticipants,
          "net"
        );
        this.form[this.rep].tax += this.sumPivot(
          this.insurerParticipants,
          "tax"
        );
      }
    },
    initializeBookEntryForm() {
      this.form[this.rep].net = this.sumPivot(
        this.insuranceAncillariesBreakdowns.breakdowns,
        "net"
      );
      this.form[this.rep].gross =
        this.sumPivot(this.insuranceAncillariesBreakdowns.breakdowns, "net") +
        this.sumPivot(this.insuranceAncillariesBreakdowns.breakdowns, "tax");
      this.form[this.rep].tax = this.sumPivot(
        this.insuranceAncillariesBreakdowns.breakdowns,
        "tax"
      );
      this.form[this.rep].agency_prov_purchase = this.sumPivot(
        this.insuranceAncillariesAgencyBreakdowns.breakdowns,
        "agency_prov_purchase"
      );
      this.form[this.rep].agency_prov_take = this.sumPivot(
        this.insuranceAncillariesAgencyBreakdowns.breakdowns,
        "agency_prov_take"
      );
      this.form[this.rep].saler_prov_purchase = this.sumPivot(
        this.insuranceAncillariesSalerBreakdowns.breakdowns,
        "saler_prov_take"
      );
      this.form[this.rep].saler_prov_take = this.sumPivot(
        this.insuranceAncillariesSalerBreakdowns.breakdowns,
        "saler_prov_purchase"
      );
    },
    storeBreakdownables() {
      for (
        let i = 0;
        i < this.insuranceAncillariesBreakdowns.breakdowns.length;
        i++
      ) {
        let breakdownId = this.insuranceAncillariesBreakdowns.breakdowns[i].id;

        this.form.breakdown[breakdownId] = {
          net: this.insuranceAncillariesBreakdowns.breakdowns[i].pivot.net,
          tax: this.insuranceAncillariesBreakdowns.breakdowns[i].pivot.tax,
          agency_prov_take:
            this.insuranceAncillariesAgencyBreakdowns.breakdowns.find(
              (brk) => brk.id === breakdownId
            ).pivot.agency_prov_take,
          agency_prov_purchase:
            this.insuranceAncillariesAgencyBreakdowns.breakdowns.find(
              (brk) => brk.id === breakdownId
            ).pivot.agency_prov_purchase,
          saler_prov_take:
            this.insuranceAncillariesSalerBreakdowns.breakdowns.find(
              (brk) => brk.id === breakdownId
            ).pivot.saler_prov_take,
          saler_prov_purchase:
            this.insuranceAncillariesSalerBreakdowns.breakdowns.find(
              (brk) => brk.id === breakdownId
            ).pivot.saler_prov_purchase,
        };
      }

      let storebreakdownables = this.addBreakdownables(
        this.titleId,
        "breakdown",
        {
          breakdown: this.form.breakdown,
        }
      );
      return storebreakdownables;
    },
    addBreakdownables(bookEntryId, relation, payload) {
      const Repo = RepositoryFactory.get("insurance_ancillary");
      return Repo.pivot_store(bookEntryId, relation, payload);
    },
    updateInsuranceAncillary() {
      let payload = this.form["insurance_ancillary"];
      payload.collaboration_type = this.form.collaboration_type;

      const Repo = RepositoryFactory.get("insurance_ancillary");
      return Repo.update(this.titleId, payload);
    },
    setBookId(risk_type, book_tags_codes) {
      let book_tag_id;

      switch (risk_type.code) {
        case "P":
          book_tag_id = this.book_tags_opt.find(
            (book_tag) => book_tag.text === book_tags_codes[0]
          ).value;
          break;
        case "L":
          book_tag_id = this.book_tags_opt.find(
            (book_tag) => book_tag.text === book_tags_codes[1]
          ).value;
          break;
        default:
          book_tag_id = this.book_tags_opt.find(
            (book_tag) => book_tag.text === book_tags_codes[2]
          ).value;
      }

      return book_tag_id;
    },
    initBookTag(ancillary_code, risk_type) {
      let book_tag_id;

      switch (ancillary_code.value) {
        // Titolo P
        case "P":
          book_tag_id = this.setBookId(risk_type, ["PAU", "PVI", "PVA"]);
          break;
        // Titolo Q
        case "Q":
          book_tag_id = this.setBookId(risk_type, ["QAU", "QVI", "QVA"]);
          break;
        // Titolo A
        case "A":
          book_tag_id = this.setBookId(risk_type, ["AAU", "AVI", "AVA"]);
          break;
      }
      if (book_tag_id) {
        this.form.book_tag[book_tag_id] = {};
      }
    },
    initDefaultValues(data) {
      // Fee
      this.form[this.rep].fee_net = data.fee_net;
      this.form[this.rep].fee_tax = data.fee_tax;
      this.form[this.rep].fee_gross = data.fee_gross;
      // Collaborator Id
      this.form.collaborator_id = data.insurance_policy?.collaborator_id;
      // Collaboration Type
      this.form.collaboration_type =
        data.insurance_policy?.collaboration_type.value;
      // Registry Id
      this.form.registry_id = data.insurance_policy?.registry_id;
      // Broker Id
      this.form.broker_id = data.broker_id;
      // Insurer Id
      this.form[this.rep].insurer_id = data.insurance_policy?.insurer_id;
      // Policy Number
      this.form[this.rep].insurance_policy_number =
        data.insurance_policy?.number;
      // Book Date
      this.form[this.rep].book_date = data.effective_at;
      // Effective Date
      this.form[this.rep].effective_date = data.effective_at;
      // Is Taxable
      this.form.book_entry.is_taxable = "Y"; //#778
      this.broker_options = this.getSalesmen();
      this.risk_branches = this.getBranches()(this.form[this.rep].insurer_id);
      this.cooperators = this.getCooperators();
      // Title (Book Entry Description) (Insurer Code + Policy Number + Registry)
      this.form.book_entry.title = `${data.insurance_policy?.insurer?.code} ${
        this.form[this.rep].insurance_policy_number
      } ${toInfoData(data?.insurance_policy?.registry, "registry", null, " ")}`;

      // Set book_tag
      if (data.insurance_policy) {
        this.initBookTag(
          data.code,
          data.insurance_policy.insurance_risk.risk_branch.risk_type
        );
      }

      // Rischio
      this.risk_type_id =
        data.insurance_policy?.insurance_risk?.risk_branch?.risk_type?.id;
      // Ramo
      this.risk_branch_id =
        data.insurance_policy?.insurance_risk?.risk_branch?.id;
      //Prodotto
      this.insurance_risk_id = data.insurance_policy?.insurance_risk?.id;

      // Prodotto
      this.products = this.getProducts()(
        this.form[this.rep].insurer_id,
        this.risk_branch_id,
        this.risk_type_id
      );

      // Breakdowns
      if (data["breakdowns"] && data.breakdowns.length != 0) {
        this.insuranceAncillariesBreakdowns = data;
        this.insuranceAncillariesAgencyBreakdowns = this.initializeProv(data);
        this.insuranceAncillariesSalerBreakdowns = this.initializeProv(data);
        this.hasBreakdown = true;
        this.hasAgencyBreakdown = true;
        this.hasSalerBreakdown = true;
      } else {
        this.form[this.rep].net = data.net;
        this.form[this.rep].tax = data.tax;
        this.form[this.rep].gross = data.gross;
        this.hasBreakdown = false;
      }

      // Insurer Participants
      const DELEGA_NOSTRA = 0;
      if (data.insurance_policy.sharing_type.value == DELEGA_NOSTRA) {
        this.hasInsurerParticipant = true;
        this.insurerParticipants = data.insurer_participants;
      }

      // if negative gross, add treasury options #559
      if (
        parseFloat(
          this.form["insurance_ancillary"].net +
            this.form["insurance_ancillary"].tax
        ) < 0
      ) {
        this.extraTreasuries.push("AC"); // (#559)
        this.$set(this, "skipDefault", []); // or AC will be skipped (#559)

        let options = this.getTreasuryOptions();
        let tmp = [];
        for (let obj of this.dtls) {
          this.$set(obj, "options", options);
          tmp.push(obj);
        }
        this.dtls = tmp;
      }
    },
    fetchInsuranceAncillariesDataById() {
      // this.isLoading = true;
      const Repo = RepositoryFactory.get("insurance_ancillary");
      let relationString =
        "relations/byInsurerParticipant/byBreakdown/byInsurancePolicy";
      return Repo.show(this.titleId, relationString)
        .then((response) => {
          const data = response.data.data;
          this.initDefaultValues(data);
          this.isLoading = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
    initializeProv(data) {
      for (let i = 0; i < data.breakdowns.length; i++) {
        data.breakdowns[i].pivot.agency_prov_purchase = 0;
        data.breakdowns[i].pivot.agency_prov_take = 0;
        data.breakdowns[i].pivot.saler_prov_take = 0;
        data.breakdowns[i].pivot.saler_prov_purchase = 0;
      }
      return data;
    },
    onAcconto() {
      let found;
      let t_id;
      found = this.$store.state.auth.treasuries.find(
        (treasury) => treasury.code === "AC"
      );
      if (found) {
        t_id = found.id;
      }
      const key = Object.keys(this.form.book_entries.entry_details).find(
        (k) => this.form.book_entries.entry_details[k].type === t_id
      );
      if (!key) {
        let index = this.dtls.length - 1;
        this.form.book_entries.entry_details[`NewIndex-${index}`] = {};
        this.form.book_entries.entry_details[`NewIndex-${index}`].type = t_id;
        this.form.book_entries.entry_details[`NewIndex-${index}`].amount =
          this.entryDetailsCreateTotal - this.details.gross;

        this.selectCreateEntryDetail(
          this.form.book_entries.entry_details,
          t_id,
          index
        );
      } else {
        this.form.book_entries.entry_details[key].amount =
          this.entryDetailsCreateTotal - this.details.gross;
      }
    },
    onSuccess() {
      this.$showSnackbar({
        preset: "success",
        text: `Azione Completata: Movimento Contabile Creato (IV)`,
      });
      this.shortcut(
        "module.TIT.TINS",
        null,
        null,
        "filterInsuranceAncillaries"
      );
    },
    onFailure(error) {
      let errMsg = this.$getErrorMessage(error);
      this.$showSnackbar({
        preset: "error",
        text: `${errMsg}`,
      });
    },
    sumPivot(array, field) {
      let initialValue = 0;
      let sum = array.reduce(function (previousValue, currentValue) {
        return previousValue + currentValue.pivot[field];
      }, initialValue);
      return sum;
    },
    grossRowInsuranceAncillaries(net, tax) {
      return toLocaleCurrency(net + tax);
    },
    agencyTakeRowAncillaries(id) {
      return toLocaleCurrency(
        this.insuranceAncillariesAgencyBreakdowns.breakdowns.find(
          (el) => el.id == id
        ).pivot.agency_prov_take
      );
    },
    agencyPurchaseRowAncillaries(id) {
      return toLocaleCurrency(
        this.insuranceAncillariesAgencyBreakdowns.breakdowns.find(
          (el) => el.id == id
        ).pivot.agency_prov_purchase
      );
    },
    grossRowAncillaries(id) {
      let rowNet = this.insuranceAncillariesBreakdowns.breakdowns.find(
        (el) => el.id == id
      ).pivot.net;
      let rowTax = this.insuranceAncillariesBreakdowns.breakdowns.find(
        (el) => el.id == id
      ).pivot.tax;
      return toLocaleCurrency(rowNet + rowTax);
    },
    ...mapGetters("auth", {
      getProducts: "products",
      getSalesmen: "salesmen",
      // getTreasuries: "treasuries",
      getExcludedTreasuries: "excludedTreasuries",
      // getInsurers: "insurers",
      getBookTags: "book_tags",
      getCooperators: "cooperators",
      getBranches: "branches",
    }),
  },
  components: {
    BaseIcon,
    BaseDatepicker,
    BaseCurrency,
    EntryDetails,
    BaseInput,
    BaseLabel,
    BaseSelect,
  },
  computed: {
    rep() {
      return this.repository;
    },
    rep_entrdtl() {
      return "entry_detail";
    },
    rep_brks() {
      return "breakdownable";
    },
    salerTakeSum: {
      get() {
        return toLocaleCurrency(
          this.sumPivot(
            this.insuranceAncillariesBreakdowns.breakdowns,
            "saler_prov_purchase"
          )
        );
      },
    },
    salerPurchaseSum: {
      get() {
        return toLocaleCurrency(
          this.sumPivot(
            this.insuranceAncillariesBreakdowns.breakdowns,
            "saler_prov_take"
          )
        );
      },
    },
    salerProvTotal: {
      get() {
        return toLocaleCurrency(
          this.sumPivot(
            this.insuranceAncillariesBreakdowns.breakdowns,
            "saler_prov_purchase"
          ) +
            this.sumPivot(
              this.insuranceAncillariesBreakdowns.breakdowns,
              "saler_prov_take"
            )
        );
      },
    },
    agencyTakeSum: {
      get() {
        return toLocaleCurrency(
          this.sumPivot(
            this.insuranceAncillariesBreakdowns.breakdowns,
            "agency_prov_take"
          )
        );
      },
    },
    agencyPurchaseSum: {
      get() {
        return toLocaleCurrency(
          this.sumPivot(
            this.insuranceAncillariesBreakdowns.breakdowns,
            "agency_prov_purchase"
          )
        );
      },
    },
    agencyProvTotal: {
      get() {
        return toLocaleCurrency(
          this.sumPivot(
            this.insuranceAncillariesBreakdowns.breakdowns,
            "agency_prov_purchase"
          ) +
            this.sumPivot(
              this.insuranceAncillariesBreakdowns.breakdowns,
              "agency_prov_take"
            )
        );
      },
    },
    insuranceAncillariesNetSum: {
      get() {
        return toLocaleCurrency(
          this.sumPivot(this.insuranceAncillariesBreakdowns.breakdowns, "net")
        );
      },
    },
    insuranceAncillariesTaxSum: {
      get() {
        return toLocaleCurrency(
          this.sumPivot(this.insuranceAncillariesBreakdowns.breakdowns, "tax")
        );
      },
    },
    insuranceAncillariesGrossSum: {
      get() {
        return toLocaleCurrency(
          this.sumPivot(this.insuranceAncillariesBreakdowns.breakdowns, "net") +
            this.sumPivot(this.insuranceAncillariesBreakdowns.breakdowns, "tax")
        );
      },
    },
    insuranceAncillariesGrossPremiumSum: {
      get() {
        return toLocaleCurrency(
          this.form[this.rep].net + this.form[this.rep].tax
        );
      },
    },
    agencyHasFee() {
      return this.$store.state.auth.use_fee === "Y" ? true : false;
    },
    entryDetailsCreateTotal() {
      return Object.keys(this.form.book_entries.entry_details)
        .map((key) => {
          return +this.form.book_entries.entry_details[key].amount || 0;
        })
        .reduce(function (sum, i) {
          return sum + i;
        }, 0);
    },
    sumEntryDetails() {
      // devo negativizzare la cassa AC...ma solamene nel Totale, non nel componente EntryDetail
      let found;
      let id;
      found = this.$store.state.auth.treasuries.find(
        (treasury) => treasury.code === "AC"
      );
      if (found) {
        id = found.id;
      }
      let clone = JSON.parse(
        JSON.stringify(this.form.book_entries.entry_details)
      );
      let ac = Object.values(clone).find((e) => e.type == id);
      if (ac) {
        ac.amount *= -1;
      }
      // return Object.keys(this.form.book_entries.entry_details)
      return Object.keys(clone)
        .map((key) => {
          // return +this.form.book_entries.entry_details[key].amount || 0;
          return +clone[key].amount || 0;
        })
        .reduce(function (sum, i) {
          return sum + i;
        }, 0);
    },
    prdct() {
      return this.products;
    },
  },
  beforeMount() {
    this.isLoading = true;
    console.log("beforeMount index claim");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("book_tags")) {
      resources.push(
        this.$store
          .dispatch(`${path}book_tags`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    } else {
      this.book_tags_opt = this.getBookTags();
      const be = this.fetchCreateForm(this.repository);
      const ed = this.fetchCreateForm(this.rep_entrdtl);
      const ia = this.fetchEditForm("insurance_ancillary", this.titleId);
      Promise.all([be, ed, ia]).then(() => {
        this.fetchInsuranceAncillariesDataById();
      });
    }
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          this.book_tags_opt = this.getBookTags();
          const be = this.fetchCreateForm(this.repository);
          const ed = this.fetchCreateForm(this.rep_entrdtl);
          const ia = this.fetchEditForm("insurance_ancillary", this.titleId);
          Promise.all([be, ed, ia]).then(() => {
            this.fetchInsuranceAncillariesDataById();
          });
          // this.isLoading = false;
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          // this.unloadMsg();
          // this.isLoading = false;
        });
    } else {
      this.isLoading = false;
    }
  },
};
</script>
